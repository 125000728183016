import React, { useEffect, useState } from "react";
import axiosInstance from "../../../services/AxiosInstance";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Pagination from "../../components/Pagination";

function Versions() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState(null);
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      version: "",
      description: "",
      changelog: "",
      force: 1,
    },
  });
  const [id, setId] = useState(0);
  const [action, setAction] = useState("create");
  const [versions, setVersions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [page, setpage] = useState(searchParams.get("page") ?? 1);
  const fetchVersions = async () => {
    setIsLoading(true);
    axiosInstance.get(`versions/all?page=${page}`).then((resp) => {
      setVersions(resp.data.data.data);
      setData(resp.data.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!searchParams.get('page')) {
      setSearchParams({page: 1})
      setpage(1)
      fetchVersions();
    }else{
      fetchVersions();
    }
  }, [page]);

  const handlePageChange = (val) => {
    setpage(val)
    setSearchParams({page: val})
  };

  const save = async (data) => {
    if (action === "create") {
      axiosInstance.post("versions/create", { ...data }).then((resp) => {
        toast.success("Version created succesfully");
        fetchVersions();
        setValue("version", "");
        setValue("description", "");
        setValue("changelog", "");
        setValue("force", 1);
        setModalOpen(false);
      });
    } else {
      axiosInstance.put(`versions/edit/${id}`, { ...data }).then((resp) => {
        toast.success("Version updated succesfully");
        fetchVersions();
        setValue("version", "");
        setValue("description", "");
        setValue("changelog", "");
        setValue("force", 1);
        setModalOpen(false);
      });
    }
  };

  const createVersion = () => {
    setAction("create");
    setModalOpen(true);
  };

  const editVersion = (data) => {
    setAction("edit");
    setValue("version", data.version);
    setValue("description", data.description);
    setValue("changelog", data.changelog);
    setValue("force", data.force);
    setId(data.id);
    setModalOpen(true);
  };

  const closeModal = () => {
    setValue("version", "");
    setValue("description", "");
    setValue("changelog", "");
    setValue("force", 1);
    setModalOpen(false);
  };

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    const sortedJobs = [...versions].sort((a, b) => {
      if (sortConfig.key) {
        const key = sortConfig.key;
        const modifier = sortConfig.direction === "asc" ? 1 : -1;
        return a[key].localeCompare(b[key]) * modifier;
      }
      return 0;
    });
    setVersions(sortedJobs);
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="mb-sm-4 d-flex flex-wrap align-items-center text-head">
            <button
              className="btn btn-primary font-w600 mr-auto"
              onClick={() => {
                createVersion();
              }}
            >
              + New Version
            </button>
          </div>
          <div className="table-responsive rounded card-table">
            <div id="subscriptions" className="dataTables_wrapper no-footer">
              <table
                className="table border-no order-table mb-4 table-responsive-lg dataTablesCard dataTable no-footer overflow-hidden"
                role="grid"
              >
                <thead>
                  <tr role="row">
                    <th
                      className="cursor-pointer"
                      onClick={() => requestSort("version")}
                    >
                      Version{" "}
                      {sortConfig.key === "version" &&
                        (sortConfig.direction === "asc" ? "↑" : "↓")}
                    </th>
                    <th>Description</th>
                    <th>Force</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="6" className="text-center">
                        <Spinner
                          variant="primary"
                          animation="border"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  ) : (
                    versions.map((version, versionId) => {
                    return (
                      <tr
                        key={versionId}
                        className="alert alert-dismissible border-0 odd"
                        role="row"
                      >
                        <td>{version.version}</td>
                        <td>{version.description}</td>
                        <td width={100}><span className={version.force === 1 ? "badge bg-success" : "badge bg-danger"} >{version.force}</span></td>
                        <td width={40}>
                          <button
                            className="btn btn-primary"
                            style={{ padding: "8px 14px" }}
                            onClick={() => {
                              editVersion(version);
                            }}
                          >
                            <i className="flaticon-381-edit-1 text-white" />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                  )};
                  {versions.length === 0 && (
                    <tr
                      className="alert alert-dismissible border-0 odd"
                      role="row"
                    >
                      <td colSpan={4} className="text-center">
                        No Version yet
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              
              {data && <Pagination
                currentPage={data.current_page}
                totalPages={data.total <= 10 ? 1 : Math.ceil(data.total / 10)}
                onPageChange={handlePageChange}
                totalData={data.total}
              />}

            </div>
          </div>
        </div>
      </div>
      <Modal className="fade" show={modalOpen} onHide={setModalOpen} centered>
        <form onSubmit={handleSubmit(save)}>
          <Modal.Header className="border-0">
            <Modal.Title>
              {action === "create" ? "Add New Version" : "Edit Version"}
            </Modal.Title>
            <Button onClick={() => closeModal()} variant="" className="close">
              <span>&times;</span>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Version</label>
              <input
                {...register("version", { required: true })}
                className="form-control"
                type="text"
              />
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea
                {...register("description", { required: true })}
                className="form-control"
                rows={4}
                aria-multiline
              />
            </div>
            <div className="form-group">
              <label>Changelog</label>
              <textarea
                {...register("changelog", { required: true })}
                className="form-control"
                rows={4}
                aria-multiline
              />
            </div>
            <div className="form-group">
              <label>Force:</label>
              <select {...register("force")} className="form-control">
                <option value={1}>1</option>
                <option value={0}>0</option>
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <Button onClick={() => closeModal()} variant="danger light">
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default Versions;
