import React, { useEffect, useState } from "react";
import ChartDonught3 from "../components/Sego/Home/donught3";
import axiosInstance from "../../services/AxiosInstance";

function Home() {
  const [summary, setSummary] = useState(null);
  const fetchSummary = async () => {
    axiosInstance.get("admins/summary-data").then((resp) => {
      setSummary(resp.data.data);
    });
  };

  useEffect(() => {
    fetchSummary();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-xl-3 col-xxl-6 col-sm-6">
          <div className="card grd-card">
            <div className="card-body">
              <div className="media align-items-center">
                <div className="media-body mr-2">
                  <h2 className="text-white font-w600">
                    {summary?.members ?? 0}
                  </h2>
                  <span className="text-white">Total Members</span>
                </div>
                <div className="d-inline-block position-relative donut-chart-sale">
                  <ChartDonught3
                    backgroundColor="#FFFFFF"
                    backgroundColor2="#FFFFFF"
                    // backgroundColor2="#2F6EA0"
                    height="100"
                    width="100"
                    value="0"
                  />
                  <small className="text-primary" style={{ marginTop: "-5px" }}>
                    <i
                      className="flaticon-381-user"
                      style={{ fontSize: 36, fontWeight: "lighter" }}
                    />
                  </small>
                  <span
                    className="circle bg-white"
                    style={{ marginTop: "-5px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-6 col-sm-6">
          <div className="card grd-card">
            <div className="card-body">
              <div className="media align-items-center">
                <div className="media-body mr-2">
                  <h2 className="text-white font-w600">
                    {summary?.partners ?? 0}
                  </h2>
                  <span className="text-white">Total Partners</span>
                </div>
                <div className="d-inline-block position-relative donut-chart-sale">
                  <ChartDonught3
                    backgroundColor="#FFFFFF"
                    backgroundColor2="#FFFFFF"
                    // backgroundColor2="#2F6EA0"
                    height="100"
                    width="100"
                    value="0"
                  />
                  <small className="text-primary" style={{ marginTop: "-5px" }}>
                    <i
                      className="flaticon-381-user-9"
                      style={{ fontSize: 36, fontWeight: "lighter" }}
                    />
                  </small>
                  <span
                    className="circle bg-white"
                    style={{ marginTop: "-5px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-6 col-sm-6">
          <div className="card grd-card">
            <div className="card-body">
              <div className="media align-items-center">
                <div className="media-body mr-2">
                  <h2 className="text-white font-w600">{summary?.jobs ?? 0}</h2>
                  <span className="text-white">Total Jobs</span>
                </div>
                <div className="d-inline-block position-relative donut-chart-sale">
                  <ChartDonught3
                    backgroundColor="#FFFFFF"
                    backgroundColor2="#FFFFFF"
                    // backgroundColor2="#2F6EA0"
                    height="100"
                    width="100"
                    value="0"
                  />
                  <small className="text-primary" style={{ marginTop: "-5px" }}>
                    <i
                      className="flaticon-381-briefcase"
                      style={{ fontSize: 36, fontWeight: "lighter" }}
                    />
                  </small>
                  <span
                    className="circle bg-white"
                    style={{ marginTop: "-5px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-6 col-sm-6">
          <div className="card grd-card">
            <div className="card-body">
              <div className="media align-items-center">
                <div className="media-body mr-2">
                  <h2 className="text-white font-w600">
                    {summary?.subscribers ?? 0}
                  </h2>
                  <span className="text-white">Total Subscriptions</span>
                </div>
                <div className="d-inline-block position-relative donut-chart-sale">
                  <ChartDonught3
                    backgroundColor="#FFFFFF"
                    backgroundColor2="#FFFFFF"
                    // backgroundColor2="#2F6EA0"
                    height="100"
                    width="100"
                    value="0"
                  />
                  <small className="text-primary" style={{ marginTop: "-5px" }}>
                    <i
                      className="flaticon-381-diploma"
                      style={{ fontSize: 36, fontWeight: "lighter" }}
                    />
                  </small>
                  <span
                    className="circle bg-white"
                    style={{ marginTop: "-5px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
