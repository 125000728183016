import React, { useEffect, useReducer, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Collapse } from "react-bootstrap";
/// Link
import { Link } from "react-router-dom";
import { MenuList } from "./Menu";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
};

const SideBar = () => {
  var d = new Date();
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const partnerMenu = MenuList.filter(item=>item.level === user?.user.level)
  const [state, setState] = useReducer(reducer, initialState);
  let handleheartBlast = document.querySelector(".heart");
  function heartBlast() {
    return handleheartBlast.classList.toggle("heart-blast");
  }

  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll],
  );

  const handleMenuActive = (status) => {
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }
  };
  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" });
    }
  };

  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  return (
    <div className="deznav">
      <PerfectScrollbar className="deznav-scroll">
        <ul className="metismenu" id="menu">
          {partnerMenu.length ? partnerMenu.map((data, index) => {
            let menuClass = data.classsChange;
            if (menuClass === "menu-title") {
              return (
                <li className={menuClass} key={index}>
                  {data.title}
                </li>
              );
            } else {
              let isActive = false;
              if (path.length === 0 && data.to === "") {
                isActive = true;
              } else {
                if (data.content) {
                  isActive =
                    state.active === data.title &&
                    path.toLowerCase().includes(data.title.toLowerCase());
                } else {
                  isActive = data.to === path;
                }
              }
              return (
                <li className={`${isActive ? "mm-active" : ""}`} key={index}>
                  {data.content && data.content.length > 0 ? (
                    <Link
                      to={"#"}
                      className="has-arrow"
                      onClick={() => {
                        handleMenuActive(data.title);
                      }}
                    >
                      {data.iconStyle}{" "}
                      <span className="nav-text">{data.title}</span>
                    </Link>
                  ) : (
                    <Link to={data.to}>
                      {data.iconStyle}
                      <span className="nav-text">{data.title}</span>
                    </Link>
                  )}
                  <Collapse in={state.active === data.title}>
                    <ul
                      className={`${
                        menuClass === "mm-collapse" ? "mm-show" : ""
                      }`}
                    >
                      {data.content &&
                        data.content.map((data, index) => {
                          return (
                            <li
                              key={index}
                              className={`${
                                state.activeSubmenu === data.title
                                  ? "mm-active"
                                  : ""
                              }`}
                            >
                              {data.content && data.content.length > 0 ? (
                                <>
                                  <Link
                                    to={data.to}
                                    className={data.hasMenu ? "has-arrow" : ""}
                                    onClick={() => {
                                      handleSubmenuActive(data.title);
                                    }}
                                  >
                                    {data.title}
                                  </Link>
                                  <Collapse
                                    in={
                                      state.activeSubmenu === data.title
                                        ? true
                                        : false
                                    }
                                  >
                                    <ul
                                      className={`${
                                        menuClass === "mm-collapse"
                                          ? "mm-show"
                                          : ""
                                      }`}
                                    >
                                      {data.content &&
                                        data.content.map((data, index) => {
                                          return (
                                            <>
                                              <li key={index}>
                                                <Link
                                                  className={`${
                                                    path === data.to
                                                      ? "mm-active"
                                                      : ""
                                                  }`}
                                                  to={data.to}
                                                >
                                                  {data.title}
                                                </Link>
                                              </li>
                                            </>
                                          );
                                        })}
                                    </ul>
                                  </Collapse>
                                </>
                              ) : (
                                <Link to={data.to}>{data.title}</Link>
                              )}
                            </li>
                          );
                        })}
                    </ul>
                  </Collapse>
                </li>
              );
            }
          }) :MenuList.map((data, index) => {
            let menuClass = data.classsChange;
            if (menuClass === "menu-title") {
              return (
                <li className={menuClass} key={index}>
                  {data.title}
                </li>
              );
            } else {
              let isActive = false;
              if (path.length === 0 && data.to === "") {
                isActive = true;
              } else {
                if (data.content) {
                  isActive =
                    state.active === data.title &&
                    path.toLowerCase().includes(data.title.toLowerCase());
                } else {
                  isActive = data.to === path;
                }
              }
              return (
                <li className={`${isActive ? "mm-active" : ""}`} key={index}>
                  {data.content && data.content.length > 0 ? (
                    <Link
                      to={"#"}
                      className="has-arrow"
                      onClick={() => {
                        handleMenuActive(data.title);
                      }}
                    >
                      {data.iconStyle}{" "}
                      <span className="nav-text">{data.title}</span>
                    </Link>
                  ) : (
                    <Link to={data.to}>
                      {data.iconStyle}
                      <span className="nav-text">{data.title}</span>
                    </Link>
                  )}
                  <Collapse in={state.active === data.title}>
                    <ul
                      className={`${
                        menuClass === "mm-collapse" ? "mm-show" : ""
                      }`}
                    >
                      {data.content &&
                        data.content.map((data, index) => {
                          return (
                            <li
                              key={index}
                              className={`${
                                state.activeSubmenu === data.title
                                  ? "mm-active"
                                  : ""
                              }`}
                            >
                              {data.content && data.content.length > 0 ? (
                                <>
                                  <Link
                                    to={data.to}
                                    className={data.hasMenu ? "has-arrow" : ""}
                                    onClick={() => {
                                      handleSubmenuActive(data.title);
                                    }}
                                  >
                                    {data.title}
                                  </Link>
                                  <Collapse
                                    in={
                                      state.activeSubmenu === data.title
                                        ? true
                                        : false
                                    }
                                  >
                                    <ul
                                      className={`${
                                        menuClass === "mm-collapse"
                                          ? "mm-show"
                                          : ""
                                      }`}
                                    >
                                      {data.content &&
                                        data.content.map((data, index) => {
                                          return (
                                            <>
                                              <li key={index}>
                                                <Link
                                                  className={`${
                                                    path === data.to
                                                      ? "mm-active"
                                                      : ""
                                                  }`}
                                                  to={data.to}
                                                >
                                                  {data.title}
                                                </Link>
                                              </li>
                                            </>
                                          );
                                        })}
                                    </ul>
                                  </Collapse>
                                </>
                              ) : (
                                <Link to={data.to}>{data.title}</Link>
                              )}
                            </li>
                          );
                        })}
                    </ul>
                  </Collapse>
                </li>
              );
            }
          })}
        </ul>
        <div className="copyright">
          <p>
            <strong>ORDES App | Dashboard</strong> © {d.getFullYear()} All
            Rights Reserved
          </p>
          <p>
            Made with{" "}
            <span className="heart" onClick={() => heartBlast()}></span> by Dev
            Team
          </p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
