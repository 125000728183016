import axios from "axios";
import { store } from "../store/store";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use((config) => {
  const state = store.getState();
  const token = state.auth.auth.token;
  config.headers.Authorization = "Bearer " + token;
  config.params = config.params || {};
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      console.log("HERE");
      localStorage.clear();
      window.location.href = "/";
    }
  },
);

export default axiosInstance;
