export const statusColor = {
  "review-job": "badge-warning",
  draft: "badge-dark",
  approved: "badge-success",
  rejected: "badge-error",
  "in-progress": "badge-info",
  "review-member": "badge-warning",
};

export const statusText = {
  "review-job": "In Review",
  draft: "Draft",
  approved: "Approved",
  rejected: "Rejected",
  "in-progress": "In Progress",
  "review-member": "In Review",
};
