import React, { useEffect, useState } from "react";
import axiosInstance from "../../../services/AxiosInstance";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import moment from "moment";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import firebaseConfig from "../../../firebaseConfig.json";

// images
import avatar1 from "../../../images/avatar/1.jpg";

function Tickets() {
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const [id, setId] = useState(0);
  const [tickets, setTickets] = useState([]);
  const [ticketReplies, setTicketReplies] = useState([]);
  const [ticket, setTicket] = useState({});
  const [openMsg, setOpenMsg] = useState(false);
  const inputFileRef = React.useRef();

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      ticket_id: 0,
      admin_id: user.user.id,
      message: "",
      image: "",
    },
  });

  const fetchTickets = async () => {
    axiosInstance.get("tickets/all").then((resp) => {
      setTickets(resp.data.data);
    });
  };

  useEffect(() => {
    fetchTickets();
  }, []);

  const fetchTicketReplies = (ticket_id) => {
    setOpenMsg(true)
    axiosInstance.get("tickets/reply/" + ticket_id).then((resp) => {
      setTicketReplies(resp.data.data.ticket_replies);
      setTicket(resp.data.data.ticket);
      setValue("ticket_id", resp.data.data.ticket.id);
      setId(ticket_id);
    });
  };

  const save = async (data) => {
    axiosInstance
      .post("tickets/reply/" + data.ticket_id, { ...data })
      .then((resp) => {
        toast.success("Message Sent...");
        setValue("message", "");
        setValue("image", "");
        fetchTicketReplies(data.ticket_id);
      });
  }
  
  const chooseFile = () => {
    /*Collecting node-element and performing click*/
    inputFileRef.current.click();
  };
  const handleUpload = async (e) => {
    const file = e.target.files[0];
    // console.log('test sdf ', file);
    const token = "ca5a280d-6a3f-4178-adf4-c2f5b360d491";
    const img = uuidv4() + "." + file.name.split(".").reverse()[0];
    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);
    const storageRef = ref(storage, img);
    uploadBytes(storageRef, file).then((snapshot) => {
      const fullPath = snapshot.metadata.fullPath;
      const bucket = snapshot.metadata.bucket;
      const filename = `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${fullPath}?alt=media&token=${token}`;
      setValue("image", filename);
    });
  };

  const msgBox = {
    padding: '15px 20px',
    borderRadius : '1.25rem',
    marginBottom: '15px'
  };
  const bgAdmin = {
    background: "#e3f9e9",
    borderColor: "#e3f9e9"
  };
  const bgMember = {
    background: "#fff3f7",
    borderColor: "#fff3f7"
  };
  return (
    <>
      <div className="row">
        <div className="col-xl-4 col-xxl-5 col-lg-5">
          <div className="card border-0 pb-0">
            <div className="card-body">
              <PerfectScrollbar
                style={{ height: "370px" }}
                id="DZ_W_Todo3"
                className="widget-media dz-scroll height370 ps ps--active-y"
              >
                <ul className="timeline">
                  {tickets.map((ticket, ticketId) => {
                    return (
                      <li key={ticketId} 
                        className={`list-group-item-action ${id === ticket.id ? "bg-light" : ""}`}
                        onClick={() => fetchTicketReplies(ticket.id)}
                      >
                        <div className="timeline-panel p-2 py-3 m-0 ">
                          <div className="media mr-2">
                            <img
                              alt="avatar"
                              width="50"
                              src={
                                ticket.profile_picture
                                  ? ticket.profile_picture
                                  : avatar1
                              }
                            />
                          </div>
                          <div className="media-body">
                            <h5 className="mb-1">
                              {ticket.name}
                              <small className="text-muted float-right">
                                {moment(new Date(ticket.created_at)).format(
                                  "DD MMMM YYYY",
                                )}
                              </small>
                            </h5>
                            <p className="mb-1">{ticket.topic}</p>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </PerfectScrollbar>
            </div>
          </div>
        </div>

        <div className="col-xl-8 col-xxl-7 col-lg-7">
          <div className="card">
            <div className={`card-body ${openMsg ? "" : "d-none"}`}>
              <PerfectScrollbar
                style={{ height: "370px" }}
                id="DZ_W_TimeLine"
                className="widget-timeline dz-scroll height370 ps ps--active-y"
              >
                <ul className="">
                  <li className="bg-member msg-box" style={{ ...bgMember, ...msgBox }}>
                    <Link
                      className="timeline-panel text-muted"
                      to="#"
                    >

                      <h5 className="mb-1">
                        {ticket.member}
                        <small className="text-muted float-right">
                          {moment(new Date(ticket.created_at)).format(
                            "DD MMMM YYYY HH:mm:ss",
                          )}
                        </small>
                      </h5>
                      <p className="mb-1">{ticket.message}</p>
                    </Link>
                  </li>

                  {ticketReplies.map((reply, replyId) => {
                    return (
                      (reply.admin_id !== null) ?
                        <li className="bg-admin msg-box text-right" style={{ ...bgAdmin, ...msgBox }}>
                          <Link
                            className="timeline-panel text-muted"
                            to="#"
                          >
                            <h5 className="mb-1 ">
                              {reply.sender}
                              <small className="text-muted float-left">{moment(new Date(reply.created_at)).format('DD MMMM YYYY HH:mm:ss')}</small>
                            </h5>
                            <p className="mb-1">
                              {reply.message}
                            </p>
                            {(reply.image) ? <img class="mw-100" src={reply.image} alt="" /> : null}
                          </Link>
                        </li>
                        :
                        <li className="bg-member msg-box" style={{ ...bgMember, ...msgBox } }>
                          <Link
                            className="timeline-panel text-muted"
                            to="#"
                          >
                            <h5 className="mb-1">
                              {reply.sender}
                              <small className="text-muted float-right">{moment(new Date(reply.created_at)).format('DD MMMM YYYY HH:mm:ss')}</small>
                            </h5>
                            <p className="mb-1">
                              {reply.message}
                            </p>
                            {(reply.image) ? <img class="mw-100" src={reply.image} alt="" /> : null}
                          </Link>
                        </li>
                    );
                  })}
                </ul>
              </PerfectScrollbar>

              <div className="card-footer type_msg">
                <form onSubmit={handleSubmit(save)}>
                  <div className="input-group">
                    <textarea
                      {...register("message", { required: true })}
                      className="form-control"
                      placeholder="Reply message..."
                    ></textarea>
                    <div className="input-group-append">
                    <Button variant="primary" type="button" onClick={chooseFile} className="btn btn-light text-black">
                        <input 
                          className="float-right d-none" type="file" 
                          ref={inputFileRef}
                          onChange={handleUpload}
                        />
                        <i style={{ fontSize: "20px" }} className="fa fa-paperclip"></i>
                    </Button>

                    <Button variant="primary" type="submit" className="btn btn-primary">
                        <i style={{ fontSize: "20px" }} className="fa fa-location-arrow"></i>
                    </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

}

export default Tickets;
