import moment from "moment";

export function money(number) {
  var rupiah = "";
  var numberrev = number
    .toString()
    .replace(".000", "")
    .replace(".00", "")
    .split("")
    .reverse()
    .join("");
  numberrev = numberrev.includes(".") ? numberrev.split(".")[1] : numberrev;
  for (var i = 0; i < numberrev.length; i++) {
    if (i % 3 === 0) {
      rupiah += numberrev.substr(i, 3) + ".";
    }
  }
  return (
    "Rp " +
    rupiah
      .split("", rupiah.length - 1)
      .reverse()
      .join("")
      .replace("-.", "-")
  );
}

export function capitalFirstLetter(letter) {
  letter = letter.toLowerCase();
  const arr = letter.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  return arr.join(" ");
}

export const months = {
  January: "Januari",
  February: "February",
  March: "Maret",
  April: "April",
  May: "Mei",
  June: "Juni",
  July: "Juli",
  August: "Agustus",
  September: "September",
  October: "Oktober",
  November: "November",
  December: "Desember",
};

export function toIndonesianDate(date) {
  const days = {
    Sunday: "Minggu",
    Monday: "Senin",
    Tuesday: "Selasa",
    Wednesday: "Rabu",
    Thursday: "Kamis",
    Friday: "Jum'at",
    Saturday: "Sabtu",
  };

  const momentDate = moment(date).format("dddd, DD MMMM YYYY");
  const day = momentDate.split(",")[0];
  const month = momentDate.split(" ")[2];

  return momentDate.replace(day, days[day]).replace(month, months[month]);
}
