import React, { useEffect, useState } from "react";
import axiosInstance from "../../../services/AxiosInstance";
import { Button, Image, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { money } from "../../../utils/string";
import { v4 as uuidv4 } from "uuid";
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import firebaseConfig from "../../../firebaseConfig.json";

function Subscriptions() {
  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      name: "",
      icon: "",
      color: "",
      price: 0,
      ordes_commission: 0,
      gmn_commission: 0,
      compulsory_saving: 0,
      tax: 0,
      benefits: "",
      status: "active",
    },
  });
  const [id, setId] = useState(0);
  const [action, setAction] = useState("create");
  const [subscriptions, setSubscriptions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const fetchSubscriptions = async () => {
    axiosInstance.get("subscriptions/all").then((resp) => {
      setSubscriptions(resp.data.data.data);
    });
  };

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const save = async (data) => {
    if (action === "create") {
      axiosInstance.post("subscriptions/create", { ...data }).then((resp) => {
        toast.success("Subscription created succesfully");
        fetchSubscriptions();
        setValue("benefits", "");
        setValue("price", "0");
        setValue("tax", "0");
        setValue("compulsory_saving", "0");
        setValue("ordes_commission", "0");
        setValue("gmn_commission", "0");
        setValue("status", "active");
        setValue("name", "");
        setValue("color", "");
        setValue("icon", "");
        setModalOpen(false);
      });
    } else {
      axiosInstance
        .put(`subscriptions/edit/${id}`, { ...data })
        .then((resp) => {
          toast.success("Subscription updated succesfully");
          fetchSubscriptions();
          setValue("benefits", "");
          setValue("price", "0");
          setValue("tax", "0");
          setValue("compulsory_saving", "0");
          setValue("ordes_commission", "0");
          setValue("gmn_commission", "0");
          setValue("status", "active");
          setValue("name", "");
          setValue("icon", "");
          setValue("color", "");
          setModalOpen(false);
        });
    }
  };

  const createSubscription = () => {
    setAction("create");
    setModalOpen(true);
  };

  const editSubscription = (data) => {
    setAction("edit");
    setValue("name", data.name);
    setValue("price", data.price);
    setValue("tax", data.tax);
    setValue("compulsory_saving", data.compulsory_saving);
    setValue("ordes_commission", data.ordes_commission);
    setValue("gmn_commission", data.gmn_commission);
    setValue("benefits", data.benefits);
    setValue("status", data.status);
    setValue("icon", data.icon);
    setValue("color", data.color);
    setId(data.id);
    setModalOpen(true);
  };

  const closeModal = () => {
    setValue("benefits", "");
    setValue("price", "0");
    setValue("tax", "0");
    setValue("compulsory_saving", "0");
    setValue("ordes_commission", "0");
    setValue("gmn_commission", "0");
    setValue("status", "active");
    setValue("name", "");
    setValue("icon", "");
    setValue("color", "");
    setModalOpen(false);
  };

  const icon = watch("icon");

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const token = "ca5a280d-6a3f-4178-adf4-c2f5b360d491";
    const img = uuidv4() + "." + file.name.split(".").reverse()[0];
    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);
    const storageRef = ref(storage, img);
    uploadBytes(storageRef, file).then((snapshot) => {
      const fullPath = snapshot.metadata.fullPath;
      const bucket = snapshot.metadata.bucket;
      const filename = `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${fullPath}?alt=media&token=${token}`;
      setValue("icon", filename);
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="mb-sm-4 d-flex flex-wrap align-items-center text-head">
            <button
              className="btn btn-primary font-w600 mr-auto"
              onClick={() => {
                createSubscription();
              }}
            >
              + New Subscription
            </button>
          </div>
          <div className="table-responsive rounded card-table">
            <div id="subscriptions" className="dataTables_wrapper no-footer">
              <table
                className="table border-no order-table mb-4 table-responsive-lg dataTablesCard dataTable no-footer overflow-hidden"
                role="grid"
              >
                <thead>
                  <tr role="row">
                    <th>Subscription Name</th>
                    <th>Icon</th>
                    <th>Color</th>
                    <th>Price</th>
                    <th>Benefits</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {subscriptions.map((subscription, subscriptionId) => {
                    return (
                      <tr
                        key={subscriptionId}
                        className="alert alert-dismissible border-0 odd"
                        role="row"
                      >
                        <td>{subscription.name}</td>
                        <td width={60}>
                          <Image
                            className="rounded"
                            src={subscription.icon}
                            width={60}
                            alt={subscription.name}
                          />{" "}
                        </td>
                        <td>
                          <div
                            className="rounded"
                            style={{
                              backgroundColor: subscription.color,
                              width: 60,
                              height: 60,
                            }}
                          ></div>
                        </td>
                        <td>{money(subscription.price)}</td>
                        <td>{subscription.benefits}</td>
                        <td width={100}>{subscription.status}</td>
                        <td width={40}>
                          <button
                            className="btn btn-primary"
                            style={{
                              padding: "8px 14px",
                            }}
                            onClick={() => {
                              editSubscription(subscription);
                            }}
                          >
                            <i className="flaticon-381-edit-1 text-white" />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                  {subscriptions.length === 0 && (
                    <tr
                      className="alert alert-dismissible border-0 odd"
                      role="row"
                    >
                      <td colSpan={5} className="text-center">
                        No subscription yet
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal className="fade" show={modalOpen} onHide={setModalOpen} centered>
        <form onSubmit={handleSubmit(save)}>
          <Modal.Header className="border-0">
            <Modal.Title>
              {action === "create"
                ? "Add New Subscription"
                : "Edit Subscription"}
            </Modal.Title>
            <Button onClick={() => closeModal()} variant="" className="close">
              <span>&times;</span>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="row p-0">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    {...register("name", {
                      required: true,
                    })}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Price</label>
                  <input
                    {...register("price", {
                      required: true,
                    })}
                    className="form-control"
                    type="number"
                  />
                </div>
              </div>
            </div>
            <div className="row p-0">
              <div className="col-md-6">
                <div className="form-group">
                  <label>ORDES Commission</label>
                  <input
                    {...register("ordes_commission", {
                      required: true,
                    })}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>GMN Commission</label>
                  <input
                    {...register("gmn_commission", {
                      required: true,
                    })}
                    className="form-control"
                    type="number"
                  />
                </div>
              </div>
            </div>
            <div className="row p-0">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Compulsory Saving</label>
                  <input
                    {...register("compulsory_saving", {
                      required: true,
                    })}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>TAX</label>
                  <input
                    {...register("tax", {
                      required: true,
                    })}
                    className="form-control"
                    type="number"
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>Logo</label>
              <div className="input-group">
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    onChange={handleUpload}
                  />
                  <label className="custom-file-label">
                    {icon ? icon.substring(0, 36) + "..." : "Choose file"}
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>Benefits (separate by comma for multiple)</label>
              <textarea
                {...register("benefits", { required: true })}
                className="form-control"
                rows={4}
                aria-multiline
              />
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Color</label>
                  <input
                    {...register("color", {
                      required: true,
                    })}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Status:</label>
                  <select {...register("status")} className="form-control">
                    <option value={"active"}>Active</option>
                    <option value={"inactive"}>Inactive</option>
                  </select>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <Button onClick={() => closeModal()} variant="danger light">
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default Subscriptions;
