import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import ThemeContext from "./context/ThemeContext";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename="/">
        <ThemeContext>
          <App />
          <ToastContainer
            position="bottom-left"
            hideProgressBar
            transition={Slide}
            autoClose={3000}
            closeOnClick
            className={"rounded-xl"}
            closeButton={false}
            icon={undefined}
            toastClassName={(context) =>
              `rounded-xl ${
                context?.type === "success" ? "bg-green-light" : "bg-red"
              } w-fit relative flex px-4 py-3 min-h-10 rounded-xl justify-between cursor-pointer flex-row items-center`
            }
          />
        </ThemeContext>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);
reportWebVitals();
