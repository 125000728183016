import React, { useEffect, useState } from "react";
import axiosInstance from "../../../services/AxiosInstance";
import { toast } from "react-toastify";
import {
  Button,
  Dropdown,
  Image,
  Modal,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { formTypes } from "../../../vars/formTypes";
import { money } from "../../../utils/string";
import moment from "moment";
import { Link, useSearchParams } from "react-router-dom";
import Pagination from "../../components/Pagination";

function MemberSubscription() {
  const [memberBanks, setMemberBanks] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState([]);
  const [showPayment, setShowPayment] = useState(false);
  const [openToggle, setOpenToggle] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [page, setpage] = useState(searchParams.get("page") ?? 1);
  const [data, setData] = useState(null);


  const fetchMemberBanks = async () => {
    setIsLoading(true);
    axiosInstance.get(`subscription-payments?page=${page}`).then((resp) => {
      if(page === 'all'){
        setMemberBanks(resp.data.data);
      } else {
        setData(resp.data.data)
        setMemberBanks(resp.data.data.data);
      }
      setIsLoading(false);
    });
  };


  const showAllData = () => {
    setpage('all')
  }

  const showPage = () => {
    setpage('1')
  }

  useEffect(() => {
    if (!searchParams.get("page")) {
      setSearchParams({ page: 1 });
      setpage(1);
      fetchMemberBanks();
    } else {
      fetchMemberBanks();
    }
  }, [page]);

  const searchValue = (value) => {
    if (value) {
      setpage('all')
      const searchMemberBanks = memberBanks.filter(
        (item) =>
          item.name.toLowerCase().includes(value.toLowerCase())
      );
      setMemberBanks(searchMemberBanks);
    } else {
      fetchMemberBanks();
    }
  };

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    const sortedMemberBanks = [...memberBanks].sort((a, b) => {
      if (sortConfig.key) {
        const key = sortConfig.key;
        const modifier = sortConfig.direction === "asc" ? 1 : -1;
        if (key === "balance") return (a[key] - b[key]) * modifier;
        if (key === "start_date" || key === "end_date")
          return (new Date(a[key]) - new Date(b[key])) * modifier;
        return a[key].localeCompare(b[key]) * modifier;
      }
      return 0;
    });
    setMemberBanks(sortedMemberBanks);
  };

  const filterStatus = (status) => {
    setpage('all')
    let filterredStatus = memberBanks;
    switch (status) {
      case "Free":
        filterredStatus = memberBanks.filter(
          (item) => item.subscription === status,
        );
        break;
      case "PRO":
        filterredStatus = memberBanks.filter(
          (item) => item.subscription === status,
        );
        break;
      default:
        break;
    }
    setMemberBanks(filterredStatus);
    setOpenToggle(!openToggle);
  };

  const handlePageChange = (val) => {
    setpage(val);
    setSearchParams({ page: val });
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="mb-sm-4 d-flex flex-wrap align-items-center text-head">
          <button
              className={page==='all' ? "btn btn-outline-primary font-w600 mr-2 active text-white" : "btn btn-outline-primary font-w600 mr-2 "}
              onClick={showAllData}
            >
              All Data
            </button>
          <button
              className={page!=='all' ? "btn btn-outline-primary font-w600 mr-auto active text-white" : "btn btn-outline-primary font-w600 mr-auto"}
              onClick={showPage}
            >
              Show Page
            </button>
            <Dropdown
              show={openToggle}
              onToggle={() => setOpenToggle(!openToggle)}
              className="dropdown mb-2 ml-auto mr-3"
            >
              <Dropdown.Toggle
                className="i-false btn btn-primary btn-rounded light"
                data-toggle="dropdown"
                aria-expanded="false"
                onClick={() => {
                  setOpenToggle(true);
                  setpage('all')
                  fetchMemberBanks()
                }}
              >
                <i className="las la-bolt scale5 mr-3" />
                {isLoading ? "Loading..." : "All Subscription"}
                <i className="las la-angle-down ml-3" />
              </Dropdown.Toggle>
              {isLoading ? (
                <></>
              ) : (
                <Dropdown.Menu className="dropdown-menu dropdown-menu-center">
                  <Dropdown.Item
                    className="dropdown-item"
                    onClick={() => filterStatus("Free")}
                  >
                    <span className="text-success">Free</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown-item"
                    onClick={() => filterStatus("PRO")}
                  >
                    <span className="text-warning">PRO</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              )}
            </Dropdown>
            <div className="input-group search-area d-xl-inline-flex d-none">
              <input
                type="text"
                className="form-control"
                placeholder="Search here..."
                onChange={(e) => searchValue(e.target.value)}
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </span>
              </div>
            </div>
          </div>
          <div className="table-responsive rounded card-table">
            <div id="jobs" className="dataTables_wrapper no-footer">
              <table
                className="table border-no order-table mb-4 table-responsive-lg dataTablesCard dataTable no-footer overflow-hidden"
                role="grid"
              >
                <thead>
                  <tr role="row">
                    <th
                      className="cursor-pointer"
                      onClick={() => requestSort("name")}
                    >
                      Member Name
                      {sortConfig.key === "name" &&
                        (sortConfig.direction === "asc" ? "↑" : "↓")}
                    </th>
                    <th
                      className="cursor-pointer"
                      onClick={() => requestSort("subscription")}
                    >
                      Subscription
                      {sortConfig.key === "subscription" &&
                        (sortConfig.direction === "asc" ? "↑" : "↓")}
                    </th>
                    <th
                      className="cursor-pointer"
                      onClick={() => requestSort("start_date")}
                    >
                      Start Date{" "}
                      {sortConfig.key === "start_date" &&
                        (sortConfig.direction === "asc" ? "↑" : "↓")}
                    </th>
                    <th
                      className="cursor-pointer"
                      onClick={() => requestSort("end_date")}
                    >
                      End Date
                      {sortConfig.key === "end_date" &&
                        (sortConfig.direction === "asc" ? "↑" : "↓")}
                    </th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="7" className="text-center">
                        <Spinner
                          variant="primary"
                          animation="border"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  ) : (
                    memberBanks.map((mb, mbId) => {
                      return (
                        <tr
                          key={mbId}
                          className="alert alert-dismissible border-0 odd"
                          role="row"
                        >
                          <td>
                            {" "}
                            <OverlayTrigger
                              key={mbId}
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-${mbId}`}>
                                  show payment histories
                                </Tooltip>
                              }
                            >
                              <div
                                className="text-primary cursor-pointer"
                                onClick={() => {
                                  setShowPayment(!showPayment);
                                  setDetail(mb.payment_histories);
                                }}
                              >
                                {mb.name}
                              </div>
                            </OverlayTrigger>
                          </td>
                          <td>{mb.subscription}</td>
                          <td>
                            {moment(new Date(mb.start_date)).format(
                              "DD-MM-YYYY",
                            )}
                          </td>
                          <td>
                            {moment(new Date(mb.end_date)).format("DD-MM-YYYY")}
                          </td>
                          <td>{mb.subscription_status.toUpperCase()}</td>
                        </tr>
                      );
                    })
                  )}
                  {memberBanks.length === 0 && (
                    <tr
                      className="alert alert-dismissible border-0 odd"
                      role="row"
                    >
                      <td colSpan={6} className="text-center">
                        No Member Subscription yet
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {data && page !== 'all' && (
                <Pagination
                  currentPage={data.current_page}
                  totalPages={data.total <= data.per_page ? 1 : Math.ceil(data.total / data.per_page)}
                  onPageChange={handlePageChange}
                  totalData={data.total}
                  perPage={data.per_page}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="fade"
        show={showPayment}
        onHide={setShowPayment}
        centered
        size="lg"
      >
        <table className="table" role="grid">
          <thead className="text-weight-bold text-black">
            <tr role="row">
              <th
                className="cursor-pointer"
                onClick={() => requestSort("name")}
              >
                Subscription
                {sortConfig.key === "name" &&
                  (sortConfig.direction === "asc" ? "↑" : "↓")}
              </th>
              <th
                className="cursor-pointer"
                onClick={() => requestSort("balance")}
              >
                Amount
                {sortConfig.key === "balance" &&
                  (sortConfig.direction === "asc" ? "↑" : "↓")}
              </th>
              <th
                className="cursor-pointer"
                onClick={() => requestSort("subscription")}
              >
                Payment Link
                {sortConfig.key === "subscription" &&
                  (sortConfig.direction === "asc" ? "↑" : "↓")}
              </th>
              <th>Subscription Status</th>
              <th>Payment Status</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="7" className="text-center">
                  <Spinner variant="primary" animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </td>
              </tr>
            ) : (
              detail.map((mb, mbId) => {
                return (
                  <tr
                    key={mbId}
                    className="alert alert-dismissible border-0 odd"
                    role="row"
                  >
                    <td>{mb.subscription}</td>
                    <td>{money(mb.amount)}</td>
                    <td>{mb.payment_link}</td>
                    <td>{mb.member_subscription_status.toUpperCase()}</td>
                    <td>{mb.status.toUpperCase()}</td>
                  </tr>
                );
              })
            )}
            {detail.length === 0 && (
              <tr className="alert alert-dismissible border-0 odd" role="row">
                <td colSpan={6} className="text-center">
                  No Payment History yet
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Modal>
    </>
  );
}

export default MemberSubscription;
