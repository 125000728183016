export const formTypes = [
  {
    type: "text",
    label: "Text",
  },
  {
    type: "number",
    label: "Number",
  },
  {
    type: "textarea",
    label: "Long Text",
  },
  {
    type: "image",
    label: "Image",
  },
  {
    type: "options",
    label: "Option",
  },
  {
    type: "date",
    label: "Date",
  },
  {
    type: "pdf",
    label: "Pdf File",
  },
  {
    type: "excel",
    label: "Excel File",
  },
  {
    type: "docx",
    label: "Docx File",
  },
];
