import React, { useEffect, useState } from "react";
import axiosInstance from "../../../services/AxiosInstance";
import moment from "moment";
import { toast } from "react-toastify";
import { Button, Dropdown, Modal, Spinner } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import Pagination from "../../components/Pagination";

function JobApplicants() {
  const user = JSON.parse(localStorage.getItem("userDetails"));
  let [searchParams, setSearchParams] = useSearchParams();
  const [memberJobs, setMemberJobs] = useState([]);
  const [action, setAction] = useState("");
  const [detail, setDetail] = useState({});
  const [data, setData] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [openToggle, setOpenToggle] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [isLoading, setIsLoading] = useState(false);
  const [page, setpage] = useState(searchParams.get("page") ?? 1);

  const fetchUsers = async () => {
    setIsLoading(true);
    axiosInstance.get(`member-jobs/all?page=${page}`).then((resp) => {
      const resData = page !== 'all' ? resp.data.data.data : resp.data.data
      const metaData = page !== 'all'  ? resp.data.data : {current_page:1,total:resData.length,per_page:resData.length}
      const defaultSort = resData.sort((a, b) => {
        return (new Date(a["created_at"]) - new Date(b["created_at"])) * -1;
      });
      setMemberJobs(defaultSort);
      setData(metaData)
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!searchParams.get("page")) {
      setSearchParams({ page: 1 });
      setpage(1);
      fetchUsers();
    } else {
      fetchUsers();
    }
  }, [page]);

  const save = async (data, action) => {
    setShowConfirmation(true);
    setAction(action);
    setDetail(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let status;
    switch (action) {
      case "approve":
        status = "in-progress";
        break;
      case "done":
        status = "done";
        break;
      default:
        status = "review-member";
    }
    const newData = { ...detail, admin_id: user.user.id };
    newData.status = status;
    axiosInstance
      .put("member-jobs/approve", newData)
      .then(async (resp) => {
        toast.success(`Member Jobs has been ${action}`);
      })
      .finally(() => {
        setShowConfirmation(false);
        fetchUsers();
      });
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "review-member":
        return "text-warning font-weight-bold";
      case "in-progress":
        return "text-primary";
      case "done":
        return "text-success";
    }
  };

  const searchValue = (value) => {
    if (value) {
      const searchJobs = memberJobs.filter(
        (item) =>
          item.job.toLowerCase().includes(value.toLowerCase()) ||
          item.member.toLowerCase().includes(value.toLowerCase()),
      );
      setMemberJobs(searchJobs);
    } else {
      fetchUsers();
    }
  };

  const filterStatus = (status) => {
    let filterredStatus = memberJobs;
    switch (status) {
      case "review-member":
        filterredStatus = memberJobs.filter(
          (item) => item.member_job_status === status,
        );
        break;
      case "in-progress":
        filterredStatus = memberJobs.filter(
          (item) => item.member_job_status === status,
        );
        break;
      case "done":
        filterredStatus = memberJobs.filter(
          (item) => item.member_job_status === status,
        );
        break;
      default:
        break;
    }
    setMemberJobs(filterredStatus);
    setOpenToggle(!openToggle);
  };

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    const sortedJobs = [...memberJobs].sort((a, b) => {
      if (sortConfig.key) {
        const key = sortConfig.key;
        const modifier = sortConfig.direction === "asc" ? 1 : -1;
        if (key === "partner_fee") return (a[key] - b[key]) * modifier;
        if (key === "created_at")
          return (new Date(a[key]) - new Date(b[key])) * modifier;
        return a[key].localeCompare(b[key]) * modifier;
      }
      return 0;
    });
    setMemberJobs(sortedJobs);
  };

  const handlePageChange = (val) => {
    setpage(val);
    setSearchParams({ page: val });
  };

  const showAllData = () => {
    setpage('all')
  }

  const showPage = () => {
    setpage('1')
  }


  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="mb-sm-4 d-flex flex-wrap align-items-center text-head">
          <button
              className={page==='all' ? "btn btn-outline-primary font-w600 mr-2 active text-white" : "btn btn-outline-primary font-w600 mr-2 "}
              onClick={showAllData}
            >
              All Data
            </button>
          <button
              className={page!=='all' ? "btn btn-outline-primary font-w600 mr-auto active text-white" : "btn btn-outline-primary font-w600 mr-auto"}
              onClick={showPage}
            >
              Show Page
            </button>
            <Dropdown
              show={openToggle}
              onToggle={() => setOpenToggle(!openToggle)}
              className="dropdown mb-2 ml-auto mr-3"
            >
              <Dropdown.Toggle
                className="i-false btn btn-primary btn-rounded light"
                data-toggle="dropdown"
                aria-expanded="false"
                onClick={() => {
                  setOpenToggle(true);
                  fetchUsers();
                }}
              >
                <i className="las la-bolt scale5 mr-3" />
                {isLoading ? "Loading..." : "All Status"}
                <i className="las la-angle-down ml-3" />
              </Dropdown.Toggle>
              {isLoading ? (
                <></>
              ) : (
                <Dropdown.Menu className="dropdown-menu dropdown-menu-center">
                  <Dropdown.Item
                    className="dropdown-item"
                    onClick={() => filterStatus("review-member")}
                  >
                    <span className="text-warning">Need Approval</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown-item"
                    onClick={() => filterStatus("in-progress")}
                  >
                    <span className="text-primary">In Progress</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown-item"
                    onClick={() => filterStatus("done")}
                  >
                    <span className="text-success">Done</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              )}
            </Dropdown>
            <div className="input-group search-area d-xl-inline-flex d-none">
              <input
                type="text"
                className="form-control"
                placeholder="Search here..."
                onChange={(e) => searchValue(e.target.value)}
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </span>
              </div>
            </div>
          </div>
          <div className="table-responsive rounded card-table">
            <div id="memberJobs" className="dataTables_wrapper no-footer">
              <table
                className="table border-no order-table mb-4 table-responsive-lg dataTablesCard dataTable no-footer overflow-hidden"
                role="grid"
              >
                <thead>
                  <tr role="row">
                  <th
                      className="cursor-pointer"
                      onClick={() => requestSort("created_at")}
                    >
                      Submitted{" "}
                      {sortConfig.key === "created_at" &&
                        (sortConfig.direction === "asc" ? "↑" : "↓")}
                    </th>
                    <th
                      className="cursor-pointer"
                      onClick={() => requestSort("member")}
                    >
                      Member Name{" "}
                      {sortConfig.key === "member" &&
                        (sortConfig.direction === "asc" ? "↑" : "↓")}
                    </th>
                    <th
                      className="cursor-pointer"
                      onClick={() => requestSort("job")}
                    >
                      Job Name{" "}
                      {sortConfig.key === "job" &&
                        (sortConfig.direction === "asc" ? "↑" : "↓")}
                    </th>
       
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="5" className="text-center">
                        <Spinner
                          variant="primary"
                          animation="border"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  ) : (
                    memberJobs.map((memberJob, memberJobId) => {
                      return (
                        <tr
                          key={memberJobId}
                          className="alert alert-dismissible border-0 odd"
                          role="row"
                        >
                          <td>
                            {moment(new Date(memberJob.created_at)).format(
                              "DD-MMMM-YYYY HH:mm:ss",
                            )}
                          </td>
                          <td>{memberJob.member}</td>
                          <td>{memberJob.job}</td>
                          <td
                            className={`${getStatusClass(
                              memberJob.member_job_status,
                            )} font-weight-bold`}
                          >
                            {memberJob.member_job_status === "review-member"
                              ? "NEED APPROVAL"
                              : memberJob.member_job_status.toUpperCase()}
                          </td>
                          <td width={40}>
                            <Dropdown className="dropdown">
                              <Dropdown.Toggle
                                variant=""
                                className="i-false"
                                to="#"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                                    stroke="#3E4954"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
                                    stroke="#3E4954"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
                                    stroke="#3E4954"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                {memberJob.member_job_status ===
                                  "review-member" && (
                                  <Dropdown.Item
                                    className="dropdown-item"
                                    onClick={() => save(memberJob, "approve")}
                                  >
                                    <i className="las la-check-circle text-success mr-3 scale5" />
                                    Approve Job Applicant
                                  </Dropdown.Item>
                                )}
                                {/*                 
                          {memberJob.member_job_status === 'review-member' &&       <Dropdown.Item className="dropdown-item" onClick={()=>save(memberJob,'reject')} >
                          <i className="las la-times-circle text-danger mr-3 scale5" />
                            Reject Job Applicant
                          </Dropdown.Item>} */}

                                {memberJob.member_job_status ===
                                  "in-progress" && (
                                  <Dropdown.Item
                                    className="dropdown-item"
                                    onClick={() => save(memberJob, "done")}
                                  >
                                    <i className="las la-check-circle text-success mr-3 scale5" />
                                    Mark as Done
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })
                  )}
                  {memberJobs.length === 0 && (
                    <tr
                      className="alert alert-dismissible border-0 odd"
                      role="row"
                    >
                      <td colSpan={4} className="text-center">
                        No job applicant yet
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {data && (
                <Pagination
                  currentPage={data.current_page}
                  totalPages={data.total <= data.per_page ? 1 : Math.ceil(data.total / data.per_page)}
                  onPageChange={handlePageChange}
                  totalData={data.total}
                  perPage={data.per_page}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal show={showConfirmation} onHide={setShowConfirmation} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Member Name</label>
            <input
              className="form-control"
              type="text"
              disabled
              defaultValue={detail.member}
            />
          </div>
          <div className="form-group">
            <label>Job Name</label>
            <input
              className="form-control"
              type="text"
              disabled
              defaultValue={detail.job}
            />
          </div>

          <div className="form-group">
            <label>Submitted</label>
            <input
              className="form-control"
              type="text"
              disabled
              defaultValue={moment(detail.created_at).format(
                "DD MMM YYYY HH:MM",
              )}
            />
          </div>
          <div className="form-group">
            <label>Status</label>
            <input
              className={`${getStatusClass(
                detail.member_job_status,
              )} form-control`}
              type="text"
              disabled
              defaultValue={detail.member_job_status}
            />
          </div>
          <div className="font-weight-bold">
            Are you sure you want to {action} this job?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShowConfirmation(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={(e) => handleSubmit(e)}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default JobApplicants;
