import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Pages
import Error404 from "./pages/Error404";

//Scroll To Top
import ScrollToTop from "./layouts/ScrollToTop";
import Home from "./pages/Home";
import Members from "./pages/Members/Members";
import MemberDetails from "./pages/Members/MemberDetail";
import Admins from "./pages/Admins/Admins";
import Partners from "./pages/Partners/Partners";
import Jobs from "./pages/Jobs/Jobs";
import Subscriptions from "./pages/Subscriptions/Subscriptions";
import FAQS from "./pages/FAQS/FAQS";
import Tickets from "./pages/Tickets/Tickets";
import Login from "./pages/Login";
import JobApplicants from "./pages/Jobs/JobApplicants";
import JobSubmissions from "./pages/Jobs/JobSubmissions";
import JobCategories from "./pages/Jobs/JobCategories";
import JobDetails from "./pages/Jobs/JobDetail";
import Withdrawals from "./pages/Withdrawals/Withdrawals";
import WithdrawMember from "./pages/Withdrawals/WithdrawMember";
import MemberSubscription from "./pages/MemberSubscription/MemberSubscription";
import Slider from "./pages/Slider/Slider";
import Versions from "./pages/Versions/Versions";
import RequestWithdraw from "./pages/Withdrawals/RequestWithdraw";

const Markup = () => {
  const allroutes = [
    { url: "/login", component: <Login /> },
    { url: "/", component: <Home /> },
    { url: "/members", component: <Members /> },
    { url: "/members/details", component: <MemberDetails /> },
    { url: "/admins", component: <Admins /> },
    { url: "/partners", component: <Partners /> },
    // Jobs
    { url: "/jobs", component: <Jobs /> },
    { url: "/jobs/jobs-applicants", component: <JobApplicants /> },
    { url: "/jobs/jobs-submissions", component: <JobSubmissions /> },
    { url: "/jobs/job-detail", component: <JobDetails /> },
    { url: "/jobs/job-categories", component: <JobCategories /> },

    { url: "/subscriptions", component: <Subscriptions /> },
    { url: "/faqs", component: <FAQS /> },
    { url: "/tickets", component: <Tickets /> },

    { url: "/withdrawal", component: <Withdrawals /> },
    { url: "/withdrawal/member-withdrawal", component: <WithdrawMember /> },
    { url: "/withdrawal/withdrawal-request", component: <RequestWithdraw /> },

    { url: "/member-subscription", component: <MemberSubscription /> },

    { url: "/app-slider", component: <Slider /> },
    { url: "/versions", component: <Versions /> },
  ];

  const partnerRoutes  = [
    { url: "/login", component: <Login /> },
    { url: "/", component: <Jobs />  },
    // Jobs
    { url: "/jobs", component: <Jobs /> },
    { url: "/jobs/jobs-applicants", component: <JobApplicants /> },
    { url: "/jobs/jobs-submissions", component: <JobSubmissions /> },
    { url: "/jobs/job-detail", component: <JobDetails /> },
  ];

  const user = JSON.parse(localStorage.getItem("userDetails"));
  return (
    <>
      <Routes>
        <Route path="/*" element={<Error404 />} />
        <Route element={<MainLayout />}>
          {user.user.level === 'partners' ? partnerRoutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          )) : allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  return (
    <div id="main-wrapper" className="show">
      <Nav />
      <div
        className="content-body"
        style={{ minHeight: window.screen.height - 45 }}
      >
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Markup;
