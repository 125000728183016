import React, { useEffect, useState } from "react";
import axiosInstance from "../../../services/AxiosInstance";
import { Button, Image, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import profile from "../../../images/logo.png";
import firebaseConfig from "../../../firebaseConfig.json";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Pagination from "../../components/Pagination";

function Slider() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState(null);
  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      image_url: "",
      status: "active",
    },
  });
  const [id, setId] = useState(0);
  const [action, setAction] = useState("create");
  const [sliders, setSliders] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [page, setpage] = useState(searchParams.get("page") ?? 1);
  const fetchSliders = async () => {
    setIsLoading(true);
    axiosInstance.get(`sliders/all?page=${page}`).then((resp) => {
      setSliders(resp.data.data.data);
      setData(resp.data.data);
      setIsLoading(false);
    });
  };

  const image = watch("image_url");

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    console.log(file);
    const token = "ca5a280d-6a3f-4178-adf4-c2f5b360d491";
    const img = uuidv4() + "." + file.name.split(".").reverse()[0];
    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);
    const storageRef = ref(storage, img);
    uploadBytes(storageRef, file).then((snapshot) => {
      const fullPath = snapshot.metadata.fullPath;
      const bucket = snapshot.metadata.bucket;
      const filename = `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${fullPath}?alt=media&token=${token}`;
      setValue("image_url", filename);
    });
  };

  useEffect(() => {
    if (!searchParams.get('page')) {
      setSearchParams({page: 1})
      setpage(1)
      fetchSliders();
    }else{
      fetchSliders();
    }
  }, [page]);

  const handlePageChange = (val) => {
    setpage(val)
    setSearchParams({page: val})
  };

  const save = async (data) => {
    if (action === "create") {
      axiosInstance.post("sliders/create", { ...data }).then((resp) => {
        toast.success("Image created succesfully");
        fetchSliders();
        setValue("image_url", "");
        setModalOpen(false);
      });
    } else {
      axiosInstance.put(`sliders/edit/${id}`, { ...data }).then((resp) => {
        toast.success("Partner updated succesfully");
        fetchSliders();
        setValue("image_url", "");
        setModalOpen(false);
      });
    }
  };

  const createSlider = () => {
    setAction("create");
    setModalOpen(true);
  };

  const editSlider = (data) => {
    setAction("edit");
    setValue("image_url", data.image_url);
    setId(data.id);
    setModalOpen(true);
  };

  const closeModal = () => {
    setValue("image_url", "");
    setModalOpen(false);
  };

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    const sortedJobs = [...sliders].sort((a, b) => {
      if (sortConfig.key) {
        const key = sortConfig.key;
        const modifier = sortConfig.direction === "asc" ? 1 : -1;
        return a[key].localeCompare(b[key]) * modifier;
      }
      return 0;
    });
    setSliders(sortedJobs);
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="mb-sm-4 d-flex flex-wrap align-items-center text-head">
            <button
              className="btn btn-primary font-w600 mr-auto"
              onClick={() => createSlider()}
            >
              + New Image
            </button>
          </div>
          <div className="table-responsive rounded card-table">
            <div id="partners" className="dataTables_wrapper no-footer">
              <table
                className="table border-no order-table mb-4 table-responsive-lg dataTablesCard dataTable no-footer overflow-hidden"
                role="grid"
              >
                <thead>
                  <tr role="row">
                    <th>Id</th>
                    <th>Image</th>
                    <th
                      className="cursor-pointer"
                      onClick={() => requestSort("status")}
                    >
                      Status{" "}
                      {sortConfig.key === "status" &&
                        (sortConfig.direction === "asc" ? "↑" : "↓")}
                    </th>
                    <th
                      className="cursor-pointer"
                      onClick={() => requestSort("created_at")}
                    >
                      Created{" "}
                      {sortConfig.key === "created_at" &&
                        (sortConfig.direction === "asc" ? "↑" : "↓")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                {isLoading ? (
                    <tr>
                      <td colSpan="6" className="text-center">
                        <Spinner
                          variant="primary"
                          animation="border"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  ) : (  
                    sliders.map((slider, sliderId) => {
                      return (
                        <tr
                          key={sliderId}
                          className="alert alert-dismissible border-0 odd"
                          role="row"
                        >
                          <td>{slider.id}</td>
                          <td>
                            <Image
                              className="border rounded"
                              src={slider.image_url}
                              width={60}
                              alt={slider.id}
                            />{" "}
                          </td>
                          <td width={100}><span className={slider.status === "active" ? "badge bg-success" : "badge bg-danger"} >{slider.status}</span></td>
                          <td>
                            {moment(slider.created_at).format("DD-MMM-YYYY")}
                          </td>
                          <td width={40}>
                            <button
                              className="btn btn-primary"
                              style={{ padding: "8px 14px" }}
                              onClick={() => {
                                editSlider(slider);
                              }}
                            >
                              <i className="flaticon-381-edit-1 text-white" />
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  )};
                    {sliders.length === 0 && (
                      <tr
                        className="alert alert-dismissible border-0 odd"
                        role="row"
                      >
                        <td colSpan={7} className="text-center">
                          No Images yet
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>

              {data && <Pagination
                currentPage={data.current_page}
                totalPages={data.total <= 10 ? 1 : Math.ceil(data.total / 10)}
                onPageChange={handlePageChange}
                totalData={data.total}
              />}
              
            </div>
          </div>
        </div>
      </div>
      <Modal className="fade" show={modalOpen} onHide={setModalOpen} centered>
        <form onSubmit={handleSubmit(save)}>
          <Modal.Header className="border-0">
            <Modal.Title>
              {action === "create" ? "Add New Image" : "Edit Image"}
            </Modal.Title>
            <Button onClick={() => closeModal()} variant="" className="close">
              <span>&times;</span>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Image</label>
              <div className="input-group">
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    onChange={handleUpload}
                  />
                  <label className="custom-file-label">
                    {image ? image.substring(0, 36) + "..." : "Choose file"}
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>Status:</label>
              <select {...register("status")} className="form-control">
                <option value={"active"}>Active</option>
                <option value={"inactive"}>Inactive</option>
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <Button onClick={() => closeModal()} variant="danger light">
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default Slider;
