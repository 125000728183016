import React from "react";

import profile from "../../../images/logo.png";
import { Dropdown } from "react-bootstrap";
import Logout from "./Logout";

const Header = ({ onNote }) => {
  const user = JSON.parse(localStorage.getItem("userDetails"));
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName;
  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {finalName.join(" ").length === 0
                  ? "Dashboard"
                  : finalName.join(" ")}
                {filterName[0] === "" ? (
                  <span>Welcome to ORDES Dashboard!</span>
                ) : filterName[0] === "orders" ? (
                  <span>Here is your order list data</span>
                ) : filterName[0] === "general" ? (
                  <span>Here is your general customers list data</span>
                ) : (
                  ""
                )}
              </div>
            </div>
            <ul className="navbar-nav header-right">
              <Dropdown className="nav-item dropdown header-profile" as="li">
                <Dropdown.Toggle
                  as="a"
                  to="#"
                  variant=""
                  className="nav-link  i-false p-0c-pointer pointr"
                >
                  <img src={profile} width={20} alt="profile" />
                  <div className="header-info">
                    <span className="text-black">
                      <strong>{user.user.name}</strong>
                    </span>
                    <p className="fs-12 mb-0">{user.user.level}</p>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu align="right" className="mt-2">
                  <Logout />
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
